import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { Helmet } from "react-helmet";
import SmartCodeImg from "../images/smart_contract_code_pm.png";
import GanFaces from "../images/gan_faces_better.gif";
import ExampleCode from "../images/react_code_example.png";
import SuperFuzz from "../images/superfuzz.png";
import { Link } from "gatsby";
import * as styles from "./index.module.scss";
import { BlogComponent } from "./blog";

function Hero() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "ml_art.png" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fixed(width: 1200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <div className={styles.hero}>
      <Img
        style={{ width: "100%", minHeight: "300px", height: "30vw" }}
        fixed={data.file.childImageSharp.fixed}
        alt="NFT hero gallery"
      />
      <div className={styles.textOverlay}>
        <h1 className={styles.header}>Northwest NFTs</h1>
        <br />
        <h4 className={styles.subtitleStyle}>
          Helping artists publish non-fungible tokens since October 2021
        </h4>
      </div>
    </div>
  );
}

const IndexPage = () => {
  return (
    <main className={styles.main}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Northwest NFTs</title>
        <link rel="canonical" href="https://www.northwestnfts.com" />
      </Helmet>
      <Hero />
      <h3 className={styles.whatwedo}>What we do</h3>

      <div className={styles.what_we_do_container}>
        <div className={styles.skill_item}>
          <div className={styles.skill_text_wrap}>
            <h4>Write & Audit Smart Contracts</h4>
            <p>
              Smart contracts form the critical backend of decentralized
              applications. Bugs or exploits can cost creators and users
              millions of dollars. Our blockchain experts can help write new
              contracts for your applications and audit your existing contracts.
            </p>
          </div>
          <div className={styles.skill_img_wrap}>
            <img src={SmartCodeImg} alt="smart contract code example"></img>
          </div>
        </div>
        <div className={styles.skill_item}>
          <div className={styles.skill_text_wrap}>
            <h4>Generate NFT art</h4>
            <p>
              Do you have a great cartoon character with five hats, six shoes
              and four eyes? Let us help you mix and match your photoshop
              layers!
            </p>
          </div>
          <div className={styles.skill_img_wrap}>
            <img src={SuperFuzz} alt="a shuffle NFT we own"></img>
            <div className={styles.caption}>A shuffle NFT we own</div>
          </div>
        </div>
        <div className={styles.skill_item}>
          <div className={styles.skill_text_wrap}>
            <h4>Building minting webpages</h4>
            <p>
              We design and develop websites that use web3.js to interact with
              your contracts and the ethereum blockchain.
            </p>
          </div>
          <div className={styles.skill_img_wrap}>
            <img src={ExampleCode} alt="example react code"></img>
          </div>
        </div>
        <div className={styles.skill_item}>
          <div className={styles.skill_text_wrap}>
            <h4>GAN neural-network art</h4>
            <p>
              Northwest NFTs has expertise in generative adversarial networks or
              GANs. GANs are neural networks that can reproduce an art style
              they're trained on. We can train a GAN on your art style and see
              what striking images the GAN creates!
            </p>
          </div>
          <div className={styles.skill_img_wrap}>
            <img className={styles.gan} src={GanFaces} alt="gan face"></img>
          </div>
        </div>
      </div>
      <BlogComponent />
      <div className={styles.contact_us}>
        <a
          href="mailto:contact@northwestnfts.com?subject=Hello!"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button>Contact Us</button>
        </a>
        <p className={styles.contact}>
          <strong>contact@northwestnfts.com</strong>
        </p>
      </div>
    </main>
  );
};

export default IndexPage;
